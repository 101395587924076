<!--
 * @Author: your name
 * @Date: 2022-01-17 10:39:48
 * @LastEditTime: 2022-04-19 21:32:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/components/layouts/Footer.vue
-->
<template>
  <footer>
    <div class="w-1220 content">
      <div>
        <div class="title">友情链接</div>
        <div class="name"><a href="http://www.zhbc.com.cn/zhsj/fg/home/home.html">中华书局</a></div>
        <div class="name"><a href="http://www.gujilianhe.com/">古联（北京）数字传媒科技有限公司</a></div>
      </div>
      <div>
        <div class="title">联系我们</div>
        <div class="name">业务联系</div>
        <div class="title">010-63267059</div>
        <div class="name">技术支持</div>
        <div class="title">010-63459769</div>
        <div class="name">邮箱</div>
        <div class="title">zhbcyuedu@163.com</div>
      </div>
      <div>
        <div class="title">关于我们</div>
        <div class="wx">
          <img style="width:100%;height:100%;" src="../../assets/wxqr.jpg" alt="微信二维码">
        </div>
        <div style="text-align:center;font-size: 14px;color:#bbb;margin-top:10px;">公众号</div>
      </div>
    </div>
    <div class="copyright">
      <!-- <img style="width:18px;height:18px;position:relative;top:4px;" src="../../assets/plice.png" alt=""> -->
      <div><a href="https://beian.miit.gov.cn">京ICP备05012931号-6</a></div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
footer{
  background: #3B3C3D;
  padding: 70px 0 54px 0 !important;
  color: #fff;
}
footer .content{
  display: flex;
  justify-content: space-between;
}

footer .title{
  font-size: 20px;
}
footer .name{
  font-size: 14px;
  color: #bbb;
  margin-top: 16px;
}
footer .name a{
  color: inherit;
}
footer .name a:hover{
  color: #fff;
}
footer .wx{
  width:100px;
  height:100px;
  margin-top: 16px;
  background:#fff;
}
footer .copyright{
  text-align: center;
  color: rgba(187, 187, 187, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 24px;
  margin-top: 70px;
}

@media screen and (max-width: 640px){
  footer {
    padding: 30px 10px !important;
  }
  footer .title{
    font-size: 15px;
  }
  footer .name {
    font-size: 12px;
  }
  footer .wx {
    width: 70px;
    height: 70px;
  }
  footer .copyright {
    font-size: 12px;
    margin-top: 40px;
  }
}
</style>