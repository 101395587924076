/*
 * @Author: your name
 * @Date: 2022-02-13 18:30:32
 * @LastEditTime: 2022-03-01 21:51:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/utils/index.js
 */
export const viewPdf = (fileUrl) => {
  // const url = 'http://122.51.11.120/pdf/web/viewer.html';
  const url =
    process.env.NODE_ENV === "development"
      ? "https://zhbcyun.ancientbooks.cn/pdfjs/web/viewer.html"
      : "/pdfjs/web/viewer.html";
  const search = "?file=" + encodeURIComponent(fileUrl);
  window.open(url + search);
};

//设置cookie
export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
//获取cookie
export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return "";
}
